.CasesPage {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.case-title-background {
    position: relative;
    width: 100%;
    height: 15vw;
    top: -60%;
    min-height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.case-title-container {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0px 40px 40px -40px var(--purple);
    border: 1px solid var(--purple);
    border-top: none;
    border-radius: 0 0 40px 40px;
}

.case-title-container h1 {
    font-size: 5vw;
    width: 60%;
    margin: 0;
    text-align: center;
}

.cases-roll {
    width: 100%;
    flex: 1;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: repeat(20, 1fr);
    grid-gap: 1%;
}

.case-container {
    grid-column: 1 / 9;
    grid-row: 32 / 36;
    padding: 2%;
    background-color: var(--purple_light);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
}

.case-container li{
    font-size: 18px;
    margin-bottom: 0.5em;
}

.case {
    width: 96%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.5vw;
    margin-block-start: 0;
    margin-block-end: 0;
}

.case-container-1 {
    grid-column: 2 / 11;
    grid-row: 2 / 6;
}

.scheduling-image-container {
    grid-column: 12 / 20;
    grid-row: 2 / 10;
    display: flex;
    justify-self: center;
    align-items: center;
    justify-content: center;
}

.scheduling-image-container img {
    width: 95%;
    height: auto;
}

.case-container-2 {
    grid-column: 4 / 15;
    grid-row: 6 / 10;
}

.working-image-container {
    grid-column: 3 / 8;
    grid-row: 10 / 18;
    display: flex;
    align-items: center;
    justify-content: center;
}

.working-image-container img {
    width: 80%;
    height: auto;
}

.case-container-3 {
    grid-column: 11 / 20;
    grid-row: 10 / 12;
}

.case-container-4 {
    grid-column: 9 / 19;
    grid-row: 12 / 16;
}

.case-container-5 {
    grid-column: 8 / 18;
    grid-row: 16 / 18;
}

.study-image-container {
    grid-column: 13 / 19;
    grid-row: 18 / 25;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.study-image-container img {
    width: 40%;
    height: auto;

}

.case-container-6 {
    grid-column: 2 / 12;
    grid-row: 18 / 22;
}

.case-container-7 {
    grid-column: 3 / 13;
    grid-row: 22 / 24;
}

.collaborating-image-container {
    grid-column: 2 / 10;
    grid-row: 25 / 31;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.collaborating-image-container img {
    width: 60%;
    height: auto;
}

.case-container-8 {
    grid-column: 11 / 20;
    grid-row: 25 / 30;
}

.playing-image-container {
    grid-column: 12 / 20;
    grid-row: 30 / 38;
}

.playing-image-container img {
    width: 100%;
    height: auto;
}

.case-container-9 {
    grid-column: 2 / 12;
    grid-row: 32 / 34;
}

.case-container-10 {
    grid-column: 3 / 13;
    grid-row: 36 / 40;
}

.sign-language-image-container {
    grid-column: 12 / 20;
    grid-row: 2 / 14;
}

.sign-language-image-container img {
    width: 100%;
    height: auto;
}

.case-container-11 {
    grid-column: 2 / 11;
    grid-row: 2 / 6;
}

.case-container-12 {
    grid-column: 3 / 12;
    grid-row: 6 / 10;
}

.case-container-13 {
    grid-column: 4 / 13;
    grid-row: 10 / 14;
    white-space: pre-wrap;
    line-height: 1.5;
}

.eye-tracking-image-container {
    grid-column: 12 / 20;
    grid-row: 54 / 62;
}

.eye-tracking-image-container img {
    width: 100%;
    height: auto;
}

.case-container-14 {
    grid-column: 11 / 20;
    grid-row: 14 / 18;
}

.case-container-15 {
    grid-column: 10 / 19;
    grid-row: 18 / 22;
    white-space: pre-wrap;
}

.dysarthic-speech-image-container {
    grid-column: 2 / 10;
    grid-row: 62 / 70;
}

.dysarthic-speech-image-container img {
    width: 100%;
    height: auto;
}

.case-container-16 {
    grid-column: 2 / 11;
    grid-row: 22 / 26;
}

.case-container-17 {
    grid-column: 3 / 12;
    grid-row: 26 / 30;
    white-space: pre-wrap;
}

.cases-roll img {
    width: 100%;
    height: auto;
}

@media (orientation: portrait) {

    .cases-roll {
        margin-top: 5%;
        display: grid;
        grid-template-columns: repeat(20, 1fr);
        grid-template-rows: repeat(20, 1fr);
        grid-gap: 1%;
    }

    .span {
        flex: 1;
    }

    .case-container {
        border-radius: 20px;
    }

    .case {
        font-size: medium;
    }

    .cases-roll img {
        width: auto;
        height: 100%;
    }

    .case-container-1 {
        grid-column: 2 / 15;
        grid-row: 1 / 6;
    }

    .scheduling-image-container {
        grid-column: 15 / 20;
        grid-row: 1 / 6;
    }

    .scheduling-image-container img {
        width: 100%;
        height: auto;
    }

    .case-container-2 {
        grid-column: 7 / 20;
        grid-row: 6 / 11;
    }

    .working-image-container {
        grid-column: 2 / 7;
        grid-row: 6 / 11;
    }

    .working-image-container img {
        width: 70%;
        height: auto;
    }

    .case-container-3 {
        grid-column: 2 / 20;
        grid-row: 11 / 13;
    }

    .case-container-4 {
        grid-column: 2 / 16;
        grid-row: 13 / 18;
    }

    .study-image-container {
        grid-column: 16 / 20;
        grid-row: 13 / 18;
    }

    .study-image-container img {
        width: 100%;
        height: auto;
    }

    .case-container-5 {
        grid-column: 2 / 20;
        grid-row: 18 / 20;
    }

    .case-container-6 {
        grid-column: 2 / 20;
        grid-row: 20 / 24;
    }

    .case-container-7 {
        grid-column: 2 / 20;
        grid-row: 24 / 26;
    }

    .collaborating-image-container {
        grid-column: 2 / 8;
        grid-row: 26 / 33;
    }

    .collaborating-image-container img {
        width: 100%;
        height: auto;
    }

    .case-container-8 {
        grid-column: 8 / 20;
        grid-row: 26 / 33;
    }

    .playing-image-container {
        grid-column: 14 / 20;
        grid-row: 35 / 40;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .playing-image-container img {
        width: 60%;
        height: auto;
    }

    .case-container-9 {
        grid-column: 2 / 20;
        grid-row: 33 / 35;
    }

    .case-container-10 {
        grid-column: 2 / 14;
        grid-row: 35 / 40;
    }

    .sign-language-image-container {
        grid-column: 14 / 20;
        grid-row: 1 / 6;
    }
    
    .sign-language-image-container img {
        width: 100%;
        height: auto;
    }
    
    .case-container-11 {
        grid-column: 2 / 12;
        grid-row: 1 / 8;
    }
    
    .case-container-12 {
        grid-column: 3 / 13;
        grid-row: 8 / 15;
    }
    
    .case-container-13 {
        grid-column: 4 / 14;
        grid-row: 15 / 24;
        white-space: pre-wrap;
        line-height: 1.5;
    }
    
    .eye-tracking-image-container {
        grid-column: 14 / 20;
        grid-row: 57 / 67;
    }
    
    .eye-tracking-image-container img {
        width: 100%;
        height: auto;
    }
    
    .case-container-14 {
        grid-column: 10 / 20;
        grid-row: 24 / 31;
    }
    
    .case-container-15 {
        grid-column: 9 / 19;
        grid-row: 31 / 38;
        white-space: pre-wrap;
    }
    
    .dysarthic-speech-image-container {
        grid-column: 2 / 8;
        grid-row: 67 / 77;
    }
    
    .dysarthic-speech-image-container img {
        width: 100%;
        height: auto;
    }
    
    .case-container-16 {
        grid-column: 2 / 12;
        grid-row: 38 / 45;
    }
    
    .case-container-17 {
        grid-column: 3 / 13;
        grid-row: 45 / 52;
        white-space: pre-wrap;
    }
}