.Footer {
    width: 90%;
    height: 40px;
    padding: 0% 5% 0% 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: var(--purple);
}

.FooterCopyright {
    height: 100%;
    flex: 2;
    display: flex;
    align-items: center;
}

.ContactLink {
    height: 100%;
    flex: 2;
    display: flex;
    align-items: center;
    color: var(--purple);
}

.FooterContact {
    height: 100%;
    flex: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.FooterContactText {
    margin-left: 5px;
}

.FooterSocialMedia {
    width: 20%;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.FooterSocialIconLink {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.FooterSocialIcon {
    height: 22px;
    cursor: pointer;
    color: var(--purple);
}

@media (orientation: portrait) {
    .FooterCopyright {
        font-size: x-small;
    }

    .FooterSocialIcon {
        height: 16px;
    
    }
}