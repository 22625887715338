.ContactPage {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.description-column {
    flex: 1;
    margin: 0 2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ContactPage h1 {
    font-size: 4vw;
    margin: 0;
}

.ContactPage img {
    width: 100%;
    height: 'auto';
}

.contact-text-1 {
    font-size: large;
}

.contact-text-container {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(12, 1fr);
    width: 100%;
    height: 14vw;
    min-height: 200px;
}

.contact-idea-image-container {
    grid-column: 5 / 8;
    grid-row: 1 / 13;
}

.contact-idea-image-container img {
    width: 14vw;
    height: 14vw;
    min-width: 200px;
    min-height: 200px;
}
.contact-text-2 {
    grid-column: 1 / 6;
    grid-row: 7 / 9;
    font-size: large;
}

.contact-text-3 {
    grid-column: 1 / 6;
    grid-row: 9 / 13;
    font-size: large;
}

.form-column {
    flex: 1;
    margin: 0 2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.contact-communication-image-container {
    width: 60%;
    margin: 2vw 0;
}

.ContactForm {
    width: 100%;
    border-radius: 20px;
    box-shadow: 0 0 25vw 0 rgb(212, 187, 235);
}

@media screen and (orientation: portrait) {
    .ContactPage {
        flex-direction: column;
    }
    
    .form-column {
        margin: 5vh 2vw 0 2vw;
    }

}