.section-4 {
    width: 100%;
    max-height: 70vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section-4 h3 {
    font-size: x-large;
    font-weight: 600;
    color: black;
}

.section-4-title-container {
    width: 100%;
}
.section-4-text-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}
.section-4-item-container {
    flex: 1;
    margin: 0 2vw;
}

.collaboration-container {
    width: 100%;
}

.section-4-image {
    width: 60%;
    height: auto;
}

@media screen and (orientation: portrait) {
    .section-4 {
        width: 84vw;
        max-height: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .section-4-text-container {
        flex-direction: column;
    }
}