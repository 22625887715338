.ServicesTitle {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.services-title-container {
    width: 99.9%;
    height: 90%;
    min-height: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 0px 40px 40px -40px var(--purple);
    border-top: none;
    border-radius: 0 0 40px 40px;
    background-color: var(--purple_light);
}

.services-title-container h1 {
    font-size: 5vw;
    width: 60%;
    margin: 0;
    text-align: center;
}

.services-title-container h2 {
    font-size: x-large;
    color: black;
    text-align: center;
}

.services-title-container h3 {
    font-size: large;
    color: black;
}

.services-main-categories {
    width: 100%;
    margin: 0 1vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

@media screen and (orientation: portrait) {
    .services-title-container h1 {
        font-size: xx-large;
        width: 80%;
    }

    .services-main-categories {
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
}