.ServicesPage {
    width: 100%;
}

.services-more-info-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: repeat(20, 1fr);
}

.services-more-info-container h1 {
    grid-column: 2 / 11;
    grid-row: 3 / 9;
    font-size: 5vw;
    margin: 0;
    text-align: center;
}

.services-more-info-container p {
    grid-column: 3 / 9;
    grid-row: 10 / 17;
    font-size: 1.5vw;
    margin: 0;
    text-align: center;
}

.services-more-info-button {
    grid-column: 4 / 8;
    grid-row: 16 / 18;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: medium;
    text-align: center;
    background-color: var(--purple_light);
    color: black;
    text-decoration: none;
    border-radius: 20px;
    border: none;
    cursor: pointer;
}

.services-more-image-container {
    grid-column: 12 / 20;
    grid-row: 6 / 20;
}

.services-more-image-container img {
    width: 100%;
    height: 100%;
}

@media screen and (orientation: portrait) {
    .services-more-info-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
    .services-more-info-container h1 {
        font-size: xx-large;
    }

    .services-more-info-container p {
        font-size: medium;
        margin: 3vw;
        text-align: center;
    }

    .services-more-info-button {
        width: 35%;
        padding: 1vw;
        font-size: larger;
    }

    .services-more-image-container {
        width: 50%;
    }
}