
.LandingIntroduction {
    width: 100%;
    padding: 5vw 0;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    background-color: var(--purple_light);
    border-radius: 0 0 30px 30px;
}

.introduction-column {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.introduction-column-2-p {
    width: 80%;
    text-align: right;
}

.introduction-column-list {
    text-align: left;
}

.introduction-img-row {
    display: flex;
    padding: 0 2%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.working-image {
    width: 30%;
    height: auto;
}

.gui-image {
    width: 70%;
    height: auto;
}

.tasks-image {
    width: 90%;
    height: auto;
}

@media screen and (orientation: portrait) {
    .LandingIntroduction {
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    .introduction-column {
        width: 100vw;
        padding: 0 5vw;
    }

    .introduction-column-2-p {
        text-align: left;
    }

    .brain-image {
        width: 60vw;
        height: auto;
    }

    .introduction-img-row {
        width: 80vw;
        height: auto;
    }

    .tasks-image {
        width: 50%;
        height: auto;
    }
}