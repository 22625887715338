.LandingPage {
    width: 100%;
    min-width: 850px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.landing-title-background {
    width: 100%;
    height: 50vw;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--purple_light);
}

.landing-title-container {
    width: 120vw;
    height: 70%;
    left: -10vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 100% 100%;
    box-shadow: 0px 80px 80px -80px var(--purple);
}

body, html {
    overflow-x: hidden;
}

.landing-title-container h1 {
    font-size: 5vw;
    width: 60%;
    margin: 0;
}

.landing-title-container h2 {
    font-size: 2vw;
    width: 60%;
    color: black;
}

@media screen and (orientation: portrait) {
    .landing-title-container h1 {
        font-size: xx-large;
        width: 80%;
        margin: 0;
    }
    
    .landing-title-container h2 {
        font-size: x-large;
        width: 70%;
    }
    

}