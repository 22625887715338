.section-2 {
    width: 100%;
    max-height: 55vw;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: repeat(20, 1fr);
}

.section-2 h3 {
    font-size: x-large;
    font-weight: 600;
    color: black;
}
.trio-container {
    grid-column: 2 / 9;
    grid-row: 3 / 7;
}

.section-2-text-1-container {
    grid-column: 10 / 20;
    grid-row: 4 / 8;
    text-align: right;
}

.section-2-text-2-container {
    grid-column: 2 / 14;
    grid-row: 11 / 20;
    text-align: left;
}

.texting-container {
    grid-column: 14 / 20;
    grid-row: 10 / 19;
}

.section-2-image {
    width: 100%;
    height: auto;
}

@media screen and (orientation: portrait) {
    .section-2 {
        width: 84vw;
        margin: 2% 0;
        max-height: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .section-2-text-1-container {
        text-align: left;
    }

    .trio-container {
        width: 40vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .texting-container {
        width: 27vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}