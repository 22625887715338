body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --blue: #389cfa;
  --transparent_blue: rgba(56, 156, 250, 0.10);
  --purple: #7a10aa;
  --purple_lightest: #fbf3ff;
  --purple_light: #e2d7f299;
  --purple_dark: #4e0033;
  --purple_dark: #4e0033;
  background-color: white;
  color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
