.language-switcher-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
}

.language-switcher-container:hover {
    color: var(--blue);
}

@media (max-width: 880px) {
    .language-switcher-container {
        font-size: 12px;
    }
}