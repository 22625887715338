.ServicesGrid {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 7vw 0;
}

.services-grid-title-container {
    width: 60%;
}

.services-grid-title-container h1 {
    font-size: 5vw;
    text-align: center;
}

.services-grid {
    width: 95%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 0.2vw;
    background-color: var(--purple);
    box-shadow: 0 0 2vw 0 var(--purple);
    border-radius: 20px;
    border: 0.2vw solid var(--purple);
}

.services-grid-item {
    font-size: medium;
    background-color: white;
    padding: 1vw;
}

.services-grid .item-1-1 {
    border-radius: 20px 0 0 0;
}

.services-grid .item-1-3 {
    border-radius: 0 20px 0 0;
}

.services-grid .item-4-1 {
    border-radius: 0 0 0 20px;
}

.services-grid .item-4-3 {
    border-radius: 0 0 20px 0;
}

.services-grid .item-2-3 {
    grid-column: 3;
    grid-row: 2 / 4;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: repeat(40, 1fr);
}

.services-grid .item-2-3 img {
    width: 100%;
    height: 100%;
}

.services-grid .item-2-3 .services-human-image-container {
    grid-column: 1 / 10;
    grid-row: 3 / 38;
}

.services-grid .item-2-3 .services-robot-image-container {
    grid-column: 11 / 20;
    grid-row: 16 / 38;
}

@media screen and (orientation: portrait) {
    .services-grid {
        width: 95%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(5, 1fr);
    }
    .services-grid .item-2-3 {
        grid-column: 2;
        grid-row: 2 / 4;
    }
        
    .services-grid .item-1-2 {
        border-radius: 0 20px 0 0;
    }

    .services-grid .item-1-3 {
        border-radius: 0;
    }

    .services-grid .item-4-1 {
        border-radius: 0;
    }
    
    .services-grid .item-4-2 {
        border-radius: 0 0 0 20px;
    }
    
}