.navbar-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-container.home {
    background-color: var(--purple_light);
}

.navbar-container.services {
    background-color: var(--purple_light);
}

.navbar-container .cases, .contact {
    background-color: white;
}

.navbar {
    width: 96%;
    display: flex;
    align-items: center;
    height: 60px;
}

.navbar-brand {
    flex: 1;
}

.navbar-img {
    width: 10vw;
    max-width: 100px;
}

.navbar-menu {
    flex: 8;
    font-size: x-large;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.navbar-link {
    flex: 1;
    text-decoration: none;
    text-align: center;
    color: black;
}

.navbar-link:hover {
    color: var(--purple);
}

.language-switcher-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

@media (orientation: portrait) {
    .navbar-menu {
        font-size: smaller;
    }
}