.ContactForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.form-input {
    width: 95%;
    margin: 5px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.form-input label {
    margin: 1vw;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
    border-radius: 50px;
    background-color: transparent;
    border: 1px solid var(--purple);
    padding: 10px;
}

textarea {
    height: 80px;
    border-radius: 30px;
}

.agreement {
    margin-top: 20px;
    width: 95%;
}

input[type="checkbox"] {
    accent-color: var(--purple_light);
}

button[type="submit"] {
    display: block;
    width: 25%;
    height: 60px;
    margin: 30px auto;
    background-color: var(--purple_light);
    border: 1px solid var(--purple_light);
    border-radius: 50px;
}

button[type="submit"]:hover {
    background-color: var(--purple);
    color: white;
}

.success-message,
.error-message {
    margin-top: 10px;
    padding: 10px;
}

.success-message {
    min-height: 80px;
    background-color: #c2e0c6;
    color: #006400;
}

.error-message {
    min-height: 80px;
    background-color: #f8d7da;
    color: #721c24;
}

.message-span {
    min-height: 80px;
}