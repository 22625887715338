.section-3 {
    width: 100%;
    max-height: 70vw;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: repeat(20, 1fr);
    background-color: var(--purple_light);
    border-radius: 30px;
}

.section-3 h3 {
    font-size: x-large;
    font-weight: 600;
    color: black;
}

.design-container {
    grid-column: 2 / 11;
    grid-row: 2 / 7;
}

.section-3-text-1-container {
    grid-column: 12 / 20;
    grid-row: 2 / 8;
    text-align: right;
}

.section-3-text-2-container {
    grid-column: 7 / 20;
    grid-row: 8 / 20;
    text-align: left;
}

.section-3-text-3-container {
    grid-column: 2 / 14;
    grid-row: 13 / 20;
    text-align: left;
}

.texting-2-container {
    grid-column: 12 / 20;
    grid-row: 12 / 20;
}

.section-3-image {
    width: 100%;
    height: auto;
}

@media screen and (orientation: portrait) {
    .section-3 {
        width: 98vw;
        max-height: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .design-container {
        width: 40vw;
        display: flex;
    }

    .section-3-text-1-container {
        width: 84vw;
        text-align: left;
    }

    .section-3-text-2-container {
        width: 84vw;
    }

    .section-3-text-3-container {
        width: 84vw;
    }

    .texting-2-container {
        width: 55%;
    }

    .section-3-image {
        width: 100%;
        height: auto;
    }

}